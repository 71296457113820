<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="listdrug"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="สรุปค่าคะแนนประเมินผลการดำเนินการ โครงการดำเนินงานชุมชนยั่งยืนฯ ตามขั้นตอนการปฏิบัติ สำหรับ ภ.จว./บก.น.xls"
        :header="'สรุปค่าคะแนนประเมินผลการดำเนินการ โครงการดำเนินงานชุมชนยั่งยืนฯ ตามขั้นตอนการปฏิบัติ สำหรับ ภ.จว./บก.น.'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Download คะแนนประเมินผล
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["listdrug"],
  data() {
    return {
      json_fields: {
        สถานี: "station",
        จังหวัด: "province",
        ภาค: "area",
        "หัวหน้าสถานี(1-7)": "sum1",
        "ขั้นเตรียมการ(2.1-2.6)": "sum2",
        "ขั้นตอนการปฏิบัติ (3.1 - 3.4)": "sum3",
        "ขั้นส่งต่อความยั่งยืน (4.1- 4.3)": "sum4",
        ค่าคะแนนรวม: "sumTotal",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
