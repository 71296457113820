<template>
  <v-card class="pa-4">
    <loading :active.sync="loading"></loading>
    <h3 class="headtitle">สรุประบบประเมินโครงการ</h3>
    <v-row v-if="opendiv199">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          label="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          @change="onChangeArea()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          label="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          label="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          label="การประเมินผล"
          v-model="data.isReview"
          :items="[
            { text: 'ทั้งหมด', value: '' },
            { text: 'ประเมินผลแล้ว', value: '1' },
            { text: 'ยังไม่ได้ประเมินผล', value: '0' },
          ]"
          item-text="text"
          item-value="value"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row v-if="opendiv2">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          @change="onChangeProvince()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          label="การประเมินผล"
          v-model="data.isReview"
          :items="[
            { text: 'ทั้งหมด', value: '' },
            { text: 'ประเมินผลแล้ว', value: '1' },
            { text: 'ยังไม่ได้ประเมินผล', value: '0' },
          ]"
          item-text="text"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col> -->
    </v-row>
    <v-row v-if="opendiv3">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          label="การประเมินผล"
          v-model="data.isReview"
          :items="[
            { text: 'ทั้งหมด', value: '' },
            { text: 'ประเมินผลแล้ว', value: '1' },
            { text: 'ยังไม่ได้ประเมินผล', value: '0' },
          ]"
          item-text="text"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col> -->
    </v-row>
    <v-row v-if="opendiv4">
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกพื้นที่"
          v-model="data.area"
          :items="items2"
          item-text="text"
          item-value="group"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกจังหวัด"
          v-model="data.province"
          :items="items3"
          item-text="province"
          item-value="province"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          placeholder="เลือกสถานี"
          v-model="data.station"
          :items="items4"
          item-text="station"
          item-value="station"
          disabled
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" sm="6" xs="12">
        <v-autocomplete
          outlined
          dense
          label="การประเมินผล"
          v-model="data.isReview"
          :items="[
            { text: 'ทั้งหมด', value: '' },
            { text: 'ประเมินผลแล้ว', value: '1' },
            { text: 'ยังไม่ได้ประเมินผล', value: '0' },
          ]"
          item-text="text"
          item-value="value"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" md="1" sm="6" xs="12">
          <v-row align="center" justify="center"
            ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
              >เคลียร์</v-btn
            ></v-row
          >
        </v-col> -->
    </v-row>
    <v-col cols="12" md="12" sm="12" xs="12" v-if="!opendiv4">
      <v-row align="center" justify="center">
        <v-btn width="80px" class="mt-2 mb-4 mr-4" @click="clear()"
          >เคลียร์</v-btn
        >
        <v-btn
          width="80px"
          class="mt-2 mb-4"
          @click="searchGangs()"
          color="#833133"
          dark
          >ค้นหา</v-btn
        >
      </v-row>
      <!-- <v-col cols="12" md="1" sm="6" xs="12">
        <v-row align="center" justify="center"
          ><v-btn width="80px" class="mt-4 mb-4" @click="clear()"
            >เคลียร์</v-btn
          ></v-row
        >
      </v-col> -->
    </v-col>
    <v-row class="my-4">
      <ExportReportManageResearchForm :listdrug="listdrug" v-if="checklogin.userType == '1' || checklogin.userType == '2'|| checklogin.userType == '3' || checklogin.userType == '99'" />
    </v-row>
    <ExportReportManageResearchForm2 :listdrug="listdrug22" v-if="checklogin.userType == '1' || checklogin.userType == '2' || checklogin.userType == '99'" />
    <v-row>
      <v-col cols="12" md="4" sm="12" class="mr-4">
        <v-text-field
          class="mb-4"
          v-model="search"
          dense
          hide-details
          outlined
          placeholder="ค้นหา"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>

      <!-- <v-btn
        class="mr-4 mt-4"
        color="#833133"
        style="color: white"
        @click="createKum()"
        ><v-icon left dark> mdi-plus-circle </v-icon>เพิ่ม
      </v-btn> -->
    </v-row>
    <!-- <v-row>
        <v-col cols="12" md="4" sm="12" class="mr-0 mt-2 text-right">
          <v-chip
            >จำนวนทีไม่พบสารเสพติดทั้งหมด : {{ urineNotFound }} คน</v-chip
          >
        </v-col>
        <v-col cols="12" md="4" sm="12" class="mr-0 mt-2 text-right">
          <v-chip
            >จำนวนทีพบสารเสพติดทั้งหมด : {{ urineFound }} คน</v-chip
          >
        </v-col>
        <v-col cols="12" md="4" sm="12" class="mr-0 mt-2 text-right">
          <v-chip
            >จำนวนที x-ray ไปแล้วทั้งหมด : {{ totalNumberOfItems }} คน</v-chip
          >
        </v-col>
      </v-row> -->
    <v-data-table
      :headers="headersdrug"
      :items="listdrug"
      :search="search"
      :rows-per-page-items="listSize"
      :pagination.sync="pagination"
      :total-items="totalNumberOfItems"
      @update:pagination="paginate"
      class="elevation-1"
    >
      <template v-slot:[`item.count`]="{ index }">
        <!-- <tr> -->
        <span>{{ index + 1 }}</span>
        <!-- </tr> -->
      </template>
      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ convertDate(item.updatedAt) }}
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ convertDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.isReview`]="{ item }">
        <v-chip v-if="item.isReview == true" color="green" dark
          >ประเมินผลแล้ว</v-chip
        >
        <v-chip v-else color="orange" dark>ยังไม่ได้ประเมินผล</v-chip>
      </template>
      <template v-slot:[`item.action1`]="{ item }">
        <v-row justify="center">
          <v-icon @click="UpdateForm1(item)" class="mx-2"
            >mdi-clipboard-edit-outline</v-icon
          >
        </v-row>
      </template>
      <template v-slot:[`item.action2`]="{ item }">
        <v-row justify="center">
          <v-icon
            v-if="checklogin.userType == '1' || checklogin.userType == '2'"
            @click="viewForm2(item)"
            class="mx-2"
            >mdi-eye</v-icon
          >
          <v-icon v-else @click="UpdateForm2(item)" class="mx-2"
            >mdi-pencil</v-icon
          >
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { Decode, Encode } from "@/services";
import ExportReportManageResearchForm from "@/views/ExportExcel/ExportReportManageResearchForm";
import ExportReportManageResearchForm2 from "@/views/ExportExcel/ExportReportManageResearchForm2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
export default {
  components: {
    ExportReportManageResearchForm,
    ExportReportManageResearchForm2,
    Loading,
  },
  data() {
    return {
      loading: false,
      search: "",
      listdrug: [],
      listdrug22: [],
      count: 0,
      headersdrug: [
        { text: "ลำดับ", value: "count", align: "center", width: "100px" },
        { text: "ดูรายงาน", value: "action1", align: "center", width: "120px" },
        {
          text: "ประเมินผล",
          value: "action2",
          align: "center",
          width: "130px",
        },
        {
          text: "การประเมินผล",
          value: "isReview",
          align: "center",
          width: "150px",
        },
        { text: "สถานี", value: "station", align: "center", width: "150px" },
        { text: "จังหวัด", value: "province", align: "center", width: "150px" },
        { text: "ภาค", value: "area", align: "center", width: "150px" },

        // {
        //   text: "ผู้ทำแบบประเมิน",
        //   value: "aaa1",
        //   align: "center",
        //   width: "180px",
        // },
        {
          text: "อัพเดตเมื่อ",
          value: "updatedAt",
          align: "center",
          width: "250px",
        },

        // {
        //   text: "ผู้ประเมิน",
        //   value: "userId",
        //   align: "center",
        //   width: "180px",
        // },
        {
          text: "วันที่ประเมิน",
          value: "createdAt",
          align: "center",
          width: "250px",
        },

        // ชื่อคนประเมิน
        // วันที่
        // หน้า ค้นหา ผู้ กรอก ชื่ออะไร ผู้ ตรวจ ชื่ออะไร เวลา
        // userId: "",
        // name: "",
        // age: "",
        // phone: "",
        // position: "",
        // station: "",
      ],
      opendiv199: false,
      opendiv2: false,
      opendiv3: false,
      opendiv4: false,
      items: [],
      items2: [
        { text: "ทั่วประเทศ", group: "" },
        { text: "นครบาล", group: "น." },
        { text: "ภาค1", group: "ภ.1" },
        { text: "ภาค2", group: "ภ.2" },
        { text: "ภาค3", group: "ภ.3" },
        { text: "ภาค4", group: "ภ.4" },
        { text: "ภาค5", group: "ภ.5" },
        { text: "ภาค6", group: "ภ.6" },
        { text: "ภาค7", group: "ภ.7" },
        { text: "ภาค8", group: "ภ.8" },
        { text: "ภาค9", group: "ภ.9" },
      ],
      items3: [],
      items4: [],
      data: {
        area: "",
        province: "",
        station: "",
        isReview: "",
      },
      checklogin: "",
      totalNumberOfItems: 0,
      domains: [],
      listSize: [10, 25, 50, 100],
      pagination: {
        rowsPerPage: 10,
        page: +this.$route.query.page || 1,
        totalItems: 0,
      },
      countAll: 0,
      urineNotFound: 0,
      urineFound: 0,
    };
  },
  created() {
    this.data.isReview = "";
    this.getUser();
    this.searchGangs();
    // this.searchDropdown();
    // this.getREportXray();
  },
  methods: {
    createKum() {
      this.$router.push("addKum");
    },
    paginate(val) {
      // emitted by the data-table when changing page, rows per page, or the sorted column/direction - will be also immediately emitted after the component was created
      const query = this.$route.query;
      const obj = Object.assign({}, query);
      if (val.rowsPerPage !== this.listSize[0]) obj.limit = val.rowsPerPage;
      if (val.descending) obj.desc = "true";
      else delete obj.desc;
      obj.orderby = val.sortBy;
      obj.page = val.page;
      // check if old and new query are the same - VueRouter will not change the route if they are, so probably this is the first page loading
      let same = true;
      for (let key in query) {
        if (query[key] != obj[key]) {
          same = false;
          break;
        }
      }
      // to handle the case when a KEY exists in OBJ but not in query
      for (let key in obj) {
        if (query[key] != obj[key]) {
          same = false;
          break;
        }
      }
      if (same) this.fetchData();
      // page has been manually reloaded in the browser
      else {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: obj,
        });
      }
    },
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[1]);
    // },
    convertDate(value) {
      if (value) {
        return moment(String(value)).format("วันที่ DD/MM/YYYY เวลา hh:mm");
      }
    },
    clear() {
      this.data.area = "";
      this.data.province = "";
      this.data.station = "";
      this.data.isReview = "";
      this.getUser();
      this.searchGangs();
      // this.listdrug = [];
    },
    async onChangeArea() {
      this.items3 = [];
      this.items4 = [];
      this.data.province = "";
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?group=` + this.data.area
      );
      this.items3 = response.data.data;

      // this.items4 = response.data.data;
    },
    async onChangeProvince() {
      this.items4 = [];
      this.data.station = "";
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation?province=` +
          this.data.province
      );
      this.items4 = response.data.data;

      // this.items4 = response.data.data;
    },
    async getUser() {
      this.loading = true;
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      console.log("checklogincheckloginchecklogin", this.checklogin);
      // var data;
      // if (this.checklogin.userType !== "4") {
      if (this.checklogin.userType == "1" || this.checklogin.userType == "99") {
        this.opendiv199 = true;
        this.loading = false;
      } else if (this.checklogin.userType == "2") {
        this.data = {
          area: this.checklogin.area || "",
          province: "",
          station: "",
        };
        this.onChangeArea();
        this.opendiv2 = true;
        this.loading = false;
      } else if (this.checklogin.userType == "3") {
        this.data = {
          area: this.checklogin.area || "",
          province: this.checklogin.province || "",
          station: "",
        };
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response.data.data;
        this.onChangeProvince();

        console.log(this.data);
        this.opendiv3 = true;
        this.loading = false;
      } else if (this.checklogin.userType == "4") {
        this.data = {
          area: this.checklogin.area || "",
          province: this.checklogin.province || "",
          station: this.checklogin.station || "",
        };

        const response1 = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?group=` +
            this.data.area
        );
        this.items3 = response1.data.data;

        const response2 = await this.axios.get(
          `${process.env.VUE_APP_API}/form/getAllStation?province=` +
            this.data.province
        );
        this.items4 = response2.data.data;
        this.opendiv4 = true;
        this.loading = false;
      }
      this.loading = false;
      // }
    },
    async searchGangs() {
      this.loading = true;
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/researchForms/findAll?area=` +
          this.data.area +
          "&province=" +
          this.data.province +
          "&station=" +
          this.data.station +
          "&isReview=" +
          this.data.isReview
        // this.data
      );
      // console.log("response1", response);
      console.log("responselistdrug", response.data.summaryFormPoint);
      this.listdrug = response.data.data;
      this.listdrug22 = response.data.summaryFormPoint;
      
      // this.listdrug.aaa =
      for (let i in this.listdrug) {
        this.listdrug[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      this.loading = false;
    },

    async DeleteForm(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.axios.get(
            `${process.env.VUE_APP_API}/researchForms/delete/` + val.id
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          await location.reload();
        }
      });
    },
    UpdateForm1(val) {
      localStorage.setItem("Researchdata", Encode.encode(val));
      // this.$router.push("EditKum");
      window.open("viewResearch");
      // let routeData = this.$router.resolve({
      //   name: "ViewResearch",
      // });
      // window.open(routeData.href, "_blank");
    },
    UpdateForm2(val) {
      localStorage.setItem("Summarydata", Encode.encode(val));
      // this.$router.push("EditKum");
      window.open("ResearchForm");
      // let routeData = this.$router.resolve({
      //   name: "ViewResearch",
      // });
      // window.open(routeData.href, "_blank");
    },
    viewForm2(val) {
      localStorage.setItem("Summarydata", Encode.encode(val));
      window.open("viewResearchForm");
      // this.$router.push("viewResearchForm");
    },
    async searchDropdown() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);

      this.items2 = Array.from(
        new Set(this.items.map((a) => a.group.trim()))
      ).map((group) => {
        return this.items.find((a) => a.group.trim() === group.trim());
      });

      // this.items4.forEach((item) => {
      //   item.searchText = item.station + " " + item.village;
      // });
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
