<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="listdrug"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="ข้อมูลรายงานผลการปฏิบัติงานชุมชนยั่งยืนผ่านระบบออนไลน์.xls"
        :header="ข้อมูลรายงานผลการปฏิบัติงานชุมชนยั่งยืนผ่านระบบออนไลน์"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Download ข้อมูลรายงาน
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
export default {
  props: ["listdrug"],
  data() {
    return {
      json_fields: {
        สถานี: "station",
        จังหวัด: "province",
        ภาค: "area",
        ไฟล์: "file",
        "1.จำนวนประชากรอายุ(12-65 ปี) เพศหญิง": "q_1_1_f",
        "1.จำนวนประชากรอายุ(12-65 ปี) เพศชาย": "q_1_1_m",
        "1.จำนวนประชากรอายุ(12-65 ปี) รวม": "q_1_1_s",
        "2.จำนวนประชากรในการ X-Ray ตามข้อตกลงชุมชน เพศหญิง": "q_1_2_f",
        "2.จำนวนประชากรในการ X-Ray ตามข้อตกลงชุมชน เพศชาย": "q_1_2_m",
        "2.จำนวนประชากรในการ X-Ray ตามข้อตกลงชุมชน รวม": "q_1_2_s",
        "3.จำนวนตรวจหาสารเสพติด X-Ray เพศหญิง": "q_1_3_f",
        "3.จำนวนตรวจหาสารเสพติด X-Ray เพศชาย": "q_1_3_m",
        "3.จำนวนตรวจหาสารเสพติด X-Ray รวม": "q_1_3_s",
        "4.จำนวนพบผู้เสพยาเสพติด เพศหญิง": "q_1_4_f",
        "4.จำนวนพบผู้เสพยาเสพติด เพศชาย": "q_1_4_m",
        "4.จำนวนพบผู้เสพยาเสพติด รวม": "q_1_4_s",
        "5.จำนวนครัวเรือนจริงในชุมชน": "q_1_5",
        "6.ครัวเรือนที่ทำข้อตกลง MOU": "q_1_6",
        "7.จำนวนคุ้ม": "q_1_7",
        "8.จำนวนสมาชิกคุ้มรวมทุกคุ้ม": "q_1_8",
        "2.1.1.จำนวนผู้เสพที่ค้นพบ ก่อนส่งมอบพื้นที่": "q_2_1_1",
        "2.1.2.จำนวนผู้เข้ารับการบำบัด CBTxก่อนส่งมอบพื้นที่": "q_2_1_2",
        "2.1.3.จำนวนผู้เลิกเสพจากกระบวนการบำบัด CBTx (ตามเอกสารรับรอง สธ.)": "q_2_1_3",
        "2.1.4.จำนวนผู้เข้ารับการบำบัดโดยวิธีอื่น (ไม่ใช่ CBTx)": "q_2_1_4",
        "2.1.5.ร้อยละจำนวนผู้เสพลดลงหลังส่งมอบพื้นที่ [(3) / (2) x 100]": "q_2_1_5",
        "2.2.1.จำนวนผู้ค้าก่อนส่งมอบพื้นที่ (แจ้งเบาะแส+สืบสภาพชุมชน)": "q_2_2_1",
        "2.2.2.จำนวนผู้ค้าหลังส่งมอบพื้นที่ (ตามบันทึกจับกุม+ หลบหนี + หมายจับ )": "q_2_2_2",
        "2.2.3.ร้อยละจำนวนผู้ค้าลดลงหลังส่งมอบพื้นที่ [(2) / (1) x 100]": "q_2_2_3",
        "2.3.1.จำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติด ( x-ray + เบาะแส+ สืบสภาพชุมชน)": "q_2_3_1",
        "2.3.2.จำนวนครัวเรือนมีสมาชิกยุ่งเกี่ยวกับยาเสพติดหลังส่งมอบพื้นที่ (ตามเอกสารรับรอง สธ. + บันทึกการจับกุม + หลบหนี + (หมายจับ)": "q_2_3_2",
        "2.3.3.ร้อยละจำนวนครัวเรือนลดลงหลังส่งมอบพื้นที่ [(2) / (1) x 100]": "q_2_3_3",
        "2.4.1.จำนวนการแจ้งเบาะแส": "q_2_4_1",
        "2.4.2.จำนวนเบาะแสที่ดำเนินการแล้ว": "q_2_4_2",
        "2.4.3.ร้อยละความสำเร็จในการแก้ปัญหาจากระบบแจ้งเบาะแส [(2) / (1) x 100]": "q_2_4_3",
        "2.5.1.จำนวนผู้เข้ารับการบำบัด CBTX": "q_2_5_1",
        "2.5.2.จำนวนผู้ที่เข้ารับการศึกษาต่อ": "q_2_5_2",
        "2.5.3.จำนวนผู้ได้รับการจ้างงาน": "q_2_5_3",
        "2.5.4.จำนวนผู้ได้รับการฝึกอาชีพ": "q_2_5_4",
        "2.5.5.จำนวนผู้ได้รับจากการพัฒนาตนเอง": "q_2_5_5",
        "2.5.6.จำนวนผู้รับได้รับการช่วยเหลือเงิน หรือ ปัจจัยในการดำรงชีวิต": "q_2_5_6",
        "2.5.7.รวมจำนวนผู้ที่ได้รับการส่งเสริม (2) + (3) + (4) + (5)+(6)": "q_2_5_7",
        "2.5.8.ร้อยละจำนวนผู้เสพยาที่ร่วมโครงการในชุมชนได้รับการดูแลคุณภาพชีวิตที่ดีขึ้น [(7) / (1) x 100]": "q_2_5_8",
        "3.1 จุดแข็ง": "q_3_1",
        "3.2 จุดอ่อน": "q_3_2",
        "3.3 โอกาส": "q_3_3",
        "3.4 อุปสรรค": "q_3_4",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/xrayReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    // },
  },
};
</script>
